/deep/ .ant-spin-nested-loading {
  padding: 0 0.4rem;
}

.model .table {
  border-top: 0;
}

.th1 {
  text-align: left !important;
  padding-left: 0.3rem;
}

td {
  height: 0.6rem !important;
}

.center {
  text-align: center;
}

.tdtitle {
  position: relative;
  cursor: pointer;
}

.tdtitle:hover {
  color: #f6a75c;
}

.read {
  color: #888888 !important;
}

.read:hover {
  color: #f6a75c !important;
}